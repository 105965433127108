const UserFilter = {
    ids: {
        type: 'input',
        value: '',
    },
    email: {
        type: 'input',
        value: '',
    }
};

export default UserFilter;