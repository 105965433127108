import React, {Component} from "react";
import {FormGroup, ControlLabel, FormControl, Row, Col} from "react-bootstrap";
import Select from "react-select";

const TYPES = {
    FormControl: 'input',
    Select: 'select',
    MultiSelect: 'multiSelect'
};

export class FormRow extends Component {
    render() {
        var label;
        var field;
        var validator;

        if (this.props.label !== false) {
            label = <ControlLabel>{this.props.name}</ControlLabel>
        }

        if (this.props.type === TYPES.Select || this.props.type === TYPES.MultiSelect) {
            field = <Select
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChangeEvent}
                options={this.props.selectOptions}
                isMulti={this.props.type === TYPES.MultiSelect}
            />
        } else {
            field = <FormControl
                type={this.props.inputTypes || "text"}
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.onChangeEvent}
                placeholder={this.props.placeholder}
            />
        }

        if (this.props.validator !== undefined) {
            validator = this.props.validator.message(this.props.name, this.props.value, this.props.validationRules)
        }

        return (
            <Row>
                <Col md={12}>
                    <FormGroup>
                        {label}
                        {field}
                        {validator}
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

export default FormRow;
