import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import FormRow from "./FormRow";


class FilterForm extends Component {
    constructor(props) {
        super(props);
        this.object = this.props.object;
        let filterDefaults = {};
        Object.keys(this.object).map((key) => {
            return filterDefaults[key] = this.object[key].value
        });
        this.state = filterDefaults;
    }

    handleSubmit = event => {
        event.preventDefault();
        this.props.onSubmit(this.prepareRequestData())
    };
    handleInput = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    prepareRequestData = () => {
        let requestData = {};
        Object.keys(this.object).map((key) => {
            if (this.state[key] && this.state[key] !== null && !this.object[key].hidden) {
                return requestData[key] = this.object[key].requestNormalizer === undefined
                    ? this.state[key]
                    : this.object[key].requestNormalizer(this.state[key])
            }

            return null
        });

        return requestData
    };

    render() {
        return (
            <Row>
                <form onSubmit={this.handleSubmit}>
                    {Object.keys(this.object).map((key, index) => {
                        if (!this.object[key].hidden) {
                            return <Col md={3} key={index}>
                                <FormRow
                                    name={key}
                                    type={this.object[key].type || 'input'}
                                    value={this.state[key]}
                                    onChangeEvent={this[this.object[key].onChangeEvent] || this.handleInput}
                                    selectOptions={this.object[key].selectOptions || null}
                                    label={false}
                                    placeholder={this.object[key].placeholder || key}
                                />
                            </Col>
                        }
                        return null
                    })}
                    <Button bsStyle="primary" type="submit" style={{margin: '12px 0'}}
                            onClick={(e) => this.handleSubmit(e)}>
                        Search
                    </Button>
                    <div className="clearfix"/>
                </form>
            </Row>
        );
    }
}

export default FilterForm;
