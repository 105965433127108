import React, {Component} from "react";
import {NavItem, Nav} from "react-bootstrap";
import {Auth} from "aws-amplify";

class AdminNavbarLinks extends Component {
    constructor() {
        super();
        this.state = {
            email: 'user'
        }
        Auth.currentAuthenticatedUser().then(user => {
            this.setState({email: user.email})
        })
    }

    handleLogout = () => {
        Auth.signOut();
    };

    render() {
        return (
            <div>
                <Nav>
                </Nav>
                <Nav pullRight>
                    <NavItem onClick={this.handleLogout}>
                        Log out
                    </NavItem>
                </Nav>
            </div>
        );
    }
}

export default AdminNavbarLinks;
