import User from "./controllers/User";


const dashboardRoutes = [
    {
        path: "/users",
        name: "Users",
        icon: "pe-7s-user",
        component: User,
        layout: "/admin"
    },
];

export default dashboardRoutes;
