export default {
    "API_KEY": "PP7nCL6YVm6a7ezFaUFm65yWCBcmOMVK9FDr2lCQ",
    "API_URL": "https://videoeditor-api-admin-stage.asqq.io",
    "COGNITO_APP_CLIENT_ID": "1dvisos7dqtrqvj9dco6il78d4",
    "COGNITO_DOMAIN": "videoeditor-api-admin-stage.auth.eu-central-1.amazoncognito.com",
    "COGNITO_REDIRECT_SIGN_IN": "https://videoeditor-admin-stage.asqq.io/",
    "COGNITO_REDIRECT_SIGN_OUT": "https://videoeditor-admin-stage.asqq.io/",
    "COGNITO_USER_POOL_ID": "eu-central-1_digIb4wzN",
    "REGION": "eu-central-1",
    "RELEASE_DATE": "2020-08-28 15:19:04.915245"
};