import React, {Component} from "react";
import List from "../components/List/List";
import UserFilter from "../models/UserFilter";

class User extends Component {
    render() {
        return (
            <List
                name="Users"
                removeUrl="/admin/users"
                listApiUrl='/admin/client-users'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Email', row: 'email'},
                    {col: 'Name', row: 'name'},
                    {
                        col: 'Is Paid', row: 'is_paid', normalizer: (data) => {
                            return data.is_paid ? 'Yes' : 'No'
                        }
                    },
                    {col: 'Locale', row: 'locale'},
                    {col: 'Timezone', row: 'timezone'},
                    {col: 'Created At', row: 'created_at'},
                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={UserFilter}
            >
            </List>

        );
    }
}

export default User;
